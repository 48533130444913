@import url('https://fonts.googleapis.com/css?family=Exo+2:regular,bold,italic&display=swap');

body {
  font-family: "Exo 2";
  margin: 0;
  color: #7F7F7F;
  background-color: #FAFAFA;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #0090DF;
  opacity: 0.5;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


